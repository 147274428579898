import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Form,
  Badge,
  Col,
  Row,
  Upload,
  Checkbox,
  Input,
  Image,
  Collapse,
  message,
  Radio,
  Select,
  Divider,
  AutoComplete,
  notification,
  InputNumber,
  Spin
} from 'antd';
import CustomButton from '@/common/CustomButton';
import upload from '../../images/empty.svg';
import Close from '../../images/Closesvg';
import Pencil from '../../images/Pencilsvg';
import styles from './index.module.less';
import CustomCollapse from '@/common/CustomCollapse';
import {
  DownOutlined,
  HolderOutlined,
  PlusCircleFilled
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormItem from 'common/FormItem';
import Delete from '../../images/Deletesvg';
import DisplayFiles from '../DisplayFiles';
import { blobToFile, removeDuplicatesFromArray } from 'utilities/helpers';
import { createPDF, mergePDF, pdfArrayToBlob } from 'pdf-actions';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Link } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
import { getLoggedInUser } from 'utilities/helpers';
import AdminHeader from '../AdminHeader';
import FormatDocs from '../FormatDocs';
import { CaretDownFilled } from '@ant-design/icons';
import { debounce } from 'lodash';

const { Panel } = Collapse;
const { Dragger } = Upload;
const { Option } = Select;

const InititateEnvelopePage1 = ({
  setCategorySearch,
  handleSubCategorySearch,
  envelopeDataLoading,
  setApproverSearch,
  setSubSearch,
  select,
  setSelect,
  setFileList,
  fileList,
  itemList,
  setItemList,
  setType,
  type,
  setEnvtype,
  envtype,
  current,
  setCurrent,
  setMergedPdfBlob,
  allApprovers,
  selectedOption,
  setSelectedOption,
  setEmailmessage,
  project,
  handleSave,
  projects,
  projectId,
  setProjectId,
  signOrder,
  setSignOrder,
  customenv,
  env_type,
  formatModal,
  setFormatModal,
  departments,
  categories,
  setSelectedCategoryId,
  handleGetSubCategories,
  subcategories,
  setformValues,
  formValues,
  setSearchproject,
  selectedProject,
  setSelectedProject,
  setVendorName,
  vendors,
  setSearchbu
  // vendors,
  // setVendors
  //test
}) => {
  const onChangeCategory = (id) => {
    setSelectedCategoryId(id);
    handleGetSubCategories(id);
    setSubSearch('');
    form.setFieldsValue({ 'Sub-Category': undefined });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (emailmessage) => {
      setEmailmessage(emailmessage);
    };
    return debounce(loadOptions, 1000);
  }, []);

  const handleTextAreaChange = useCallback(
    (e) => {
      const { value } = e.target;
      debounceFetcher(value);
    },
    [debounceFetcher]
  );

  const mergePDFHandler = async () => {
    let files = [...fileList];
    const pdfDocs = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const pdfFile = await createPDF.PDFDocumentFromFile(file);
      pdfDocs.push(pdfFile);
    }

    const mergedPdfFile = await (await mergePDF(pdfDocs)).save();
    const pdfBlob = pdfArrayToBlob(mergedPdfFile);
    const finalBlob = blobToFile(pdfBlob, 'adhoc_envelope_merged.pdf');

    setMergedPdfBlob(finalBlob);
  };

  const onCheckBoxToggle = (e) => {
    e.target.checked;
    setSignOrder(e.target.checked);
    signOrder === false ? null : (
      <>
        {notification.destroy()}
        {notification.info({
          message: (
            <strong className="sfprotext-bold font-16">
              Do you want parallel approvals?
            </strong>
          ),
          description: (
            <p className="font-12">
              Unchecking this will send the envelope to all approvers
              simultaneously. Please keep it checked if you want the approvers
              to review the envelope sequentially.
            </p>
          ),
          placement: 'bottomLeft',
          duration: null
        })}
      </>
    );
  };

  let selectedRows = allApprovers?.filter(function (cv) {
    return !selectedOption.find(function (e) {
      return e?.email === cv?.email;
    });
  });

  const addInput = () => {
    var highestValue = 0; //keep track of highest value

    //loop through array of objects
    for (var i = 0, len = itemList.length; i < len; i++) {
      var value = Number(itemList[i]['id']);
      if (value > highestValue) {
        highestValue = value;
      }
    }
    let id = highestValue + 1;
    setItemList([...itemList, { id: id, value: '', envtype: '' }]);
  };
  const [form] = Form.useForm();
  // const options = [
  //   { id: '1', title: 'sun', subtitle: '1' },
  //   { id: '2', title: 'Moon', subtitle: '2' },
  //   { id: '3', title: 'Man', subtitle: '3' }
  // ];
  const removeInput = (id) => {
    let itemValues = [...itemList];
    let selectedOptions = [...selectedOption];
    itemValues.splice(id, 1);
    selectedOptions.splice(id, 1);
    setItemList(itemValues);
    setSelectedOption(selectedOptions);
  };

  const handleCapacity = (e) => {
    setSelect(e);
    setformValues({
      project_related: select,
      purchase_order: select
    });
  };
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...selectedOption];
    var updatedItemList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    const [reorderedItemList] = updatedItemList.splice(
      droppedItem.source.index,
      1
    );
    updatedItemList.splice(droppedItem.destination.index, 0, reorderedItemList);

    setItemList(updatedItemList);
    setSelectedOption(updatedList);
  };
  const handleOnChangePdfUpload = ({ fileList: onChangeFileList }) => {
    let files = fileList.concat(onChangeFileList.map((o) => o.originFileObj));

    setFileList(removeDuplicatesFromArray(files, 'name'));
  };

  const handleOnDropPdfUpload = (e) => {
    let files = fileList.concat(e.dataTransfer.files);

    setFileList(removeDuplicatesFromArray(files, 'name'));
  };

  const edit_env = (type) => {
    const user = getLoggedInUser();
    if (user?.user_details?.role === 'admin') {
      if (type === 'doa_description') {
        setType(true);
      }
      if (type === 'doa_name') {
        setEnvtype(true);
      }
      if (type === 'project_id') {
        setProjectId(true);
      }
    } else {
      if (type === 'description') {
        setType(true);
      }
      if (type === 'envelope_name') {
        setEnvtype(true);
      }
    }
  };
  const handleEdit = (value, type) => {
    const user = getLoggedInUser();
    if (user?.user_details?.role === 'admin') {
      if (type === 'doa_description') {
        localStorage.setItem('doa_description', value);
      } else if (type === 'doa_name') {
        localStorage.setItem('doa_name', value);
      } else if (type === 'project_id') {
        localStorage.setItem('project_id', value);
      }
      setType(false);
      setEnvtype(false);
      setProjectId(false);
    } else {
      if (type === 'description') {
        localStorage.setItem('description', value);
      } else if (type === 'envelope') {
        localStorage.setItem('envelope_name', value);
      }
      setType(false);
      setEnvtype(false);
    }
  };
  const handleNext = () => {
    let err = 0;
    const project = form.getFieldValue('project_related');
    const purchase_order = form.getFieldValue('purchase_order');
    const purchase_req = form.getFieldValue('purchase_req');
    const total_value = form.getFieldValue('total_value');

    if (user?.user_details?.role === 'admin') {
      localStorage.setItem(
        'doa_description',
        localStorage.getItem('doa_description')
      );
      localStorage.setItem('doa_name', localStorage.getItem('doa_name'));
      localStorage.setItem('project_id', localStorage.getItem('project_id'));
    } else {
      localStorage.setItem('description', localStorage.getItem('description'));

      localStorage.setItem(
        'envelope_name',
        localStorage.getItem('envelope_name')
      );
    }

    if (
      project === '' ||
      project === undefined ||
      purchase_order === '' ||
      purchase_order === undefined ||
      purchase_req === '' ||
      purchase_req === undefined ||
      (env_type !== 'standard' && env_type !== 'custom')
        ? total_value === undefined || total_value === ''
        : ''
    ) {
      err = 1;
    }
    if (project === 'Yes') {
      const project_code = form.getFieldValue('project_code');
      const Department = form.getFieldValue('Department');
      const min_price = form.getFieldValue('min_price');
      const max_price = form.getFieldValue('max_price');
      const Category = form.getFieldValue('Category');
      const SubCategory = form.getFieldValue('Sub-Category');
      if (
        project_code === '' ||
        Department === '' ||
        min_price === undefined ||
        max_price === undefined ||
        Category === '' ||
        SubCategory === '' ||
        project_code === undefined ||
        Department === undefined ||
        min_price === undefined ||
        max_price === undefined ||
        Category === undefined ||
        SubCategory === undefined ||
        (env_type != 'standard' && env_type != 'custom')
          ? total_value === undefined ||
            total_value === '' ||
            min_price === undefined ||
            max_price === undefined
          : ''
      ) {
        err = 1;
      }
    } else if (project === 'No') {
      const Department = form.getFieldValue('Department');

      const min_price = form.getFieldValue('min_price');
      const max_price = form.getFieldValue('max_price');
      const Category = form.getFieldValue('Category');
      const SubCategory = form.getFieldValue('Sub-Category');
      if (
        Department === '' ||
        min_price === undefined ||
        max_price === undefined ||
        Category === '' ||
        SubCategory === '' ||
        Department === undefined ||
        min_price === undefined ||
        max_price === undefined ||
        Category === undefined ||
        SubCategory === undefined ||
        (env_type !== 'standard' && env_type !== 'custom')
          ? total_value === undefined ||
            total_value === '' ||
            min_price === undefined ||
            max_price === undefined
          : ''
      ) {
        err = 1;
      }
    }
    if (purchase_order === 'Yes') {
      const budget_ava = form.getFieldValue('budget_ava');
      const maj_group = form.getFieldValue('maj_group');
      const min_group = form.getFieldValue('min_group');
      const min_price = form.getFieldValue('min_price');
      const max_price = form.getFieldValue('max_price');
      if (
        budget_ava === '' ||
        maj_group === '' ||
        min_group === '' ||
        min_price === undefined ||
        max_price === undefined ||
        budget_ava === undefined ||
        maj_group === undefined ||
        min_group === undefined
      ) {
        err = 1;
      }
    }
    if (purchase_req === 'Yes') {
      const min_price = form.getFieldValue('min_price');
      const max_price = form.getFieldValue('max_price');
      const vendor_name = form.getFieldValue('vendor_name');
      const vendor_erp_code = form.getFieldValue('vendor_erp_code');
      if (
        vendor_name === '' ||
        vendor_erp_code === '' ||
        min_price === undefined ||
        max_price === undefined ||
        vendor_name === undefined ||
        vendor_erp_code === undefined
      ) {
        err = 1;
      }
    }
    if (err === 1) {
      return message.error('Please enter all required fields');
    }
    setformValues({
      project_related: form.getFieldValue('project_related'),
      project_code: form.getFieldValue('project_code'),
      sub_category_id: form.getFieldValue('Sub-Category'),
      min_price: form.getFieldValue('min_price'),
      max_price: form.getFieldValue('max_price'),
      unit_id: form.getFieldValue('Department'),
      purchase_work_order: form.getFieldValue('purchase_order'),
      purchase_requistion: form.getFieldValue('purchase_req'),
      budget_availablity: form.getFieldValue('budget_ava'),
      main_group: form.getFieldValue('maj_group'),
      minor_group: form.getFieldValue('min_group'),
      vendor_name: form.getFieldValue('vendor_name'),
      vendor_erp_code: form.getFieldValue('vendor_erp_code'),
      Category: form.getFieldValue('Category'),
      total_value: form.getFieldValue('total_value'),
      email: form.getFieldValue('email')
    });

    if (selectedOption.length > 0 && fileList.length > 0) {
      mergePDFHandler();
      setCurrent(current + 1);
    } else {
      return message.error(
        'Please select atleast one document and one approver'
      );
    }
  };

  const onSelect = (data) => {
    setSelectedOption([
      ...selectedOption,
      { name: data.name, email: data.email, job_title: data.job_title }
    ]);
  };

  const onSelectProject = (data) => {
    setSelectedProject([...selectedProject, { project: data.project }]);
  };

  const onSelectVendor = (data) => {
    const filtered = !data
      ? vendors
      : vendors.filter((vendors) =>
          vendors.vendor_name.toLowerCase().includes(data.toLowerCase())
        );
    form.setFieldsValue({
      vendor_erp_code: filtered[0]?.vendor_code
    });
  };

  var project_list = customenv?.project
    ? projects.concat(customenv?.project)
    : projects;
  var unit_list = customenv?.unit
    ? departments.concat(customenv?.unit)
    : departments;

  useEffect(() => {
    const {
      unit_id,
      email,
      project_related,
      project_code,
      sub_category_id,
      purchase_work_order,
      purchase_requistion,
      budget_availablity,
      main_group,
      minor_group,
      vendor_name,
      vendor_erp_code,
      Category,
      total_value,
      min_price,
      max_price
    } = formValues ? formValues : {};
    setSelectedCategoryId(Category);
    console.log('FORM VALUES', formValues);
    form.setFieldsValue({
      project_related: project_related ? project_related : 'No',
      project_code: project_code,
      'Sub-Category': sub_category_id,
      min_price: min_price,
      max_price: max_price,
      Department: unit_id,
      purchase_order: purchase_work_order,
      purchase_req: purchase_requistion,
      budget_ava: budget_availablity,
      maj_group: main_group,
      min_group: minor_group,
      vendor_name,
      vendor_erp_code,
      Category,
      total_value,
      email: email
    });
  }, [current]);

  const removeInputFields = useCallback(
    (e, index) => {
      const rows = [...fileList];
      rows.splice(index, 1);
      setFileList(rows);
    },
    [fileList]
  );

  const onSearch = (value) => {
    setSearchproject(value);
  };

  const onSearchBu = (value) => {
    setSearchbu(value);
  };

  const onSearchCategory = (value) => {
    setCategorySearch(value);
  };

  const handleApproverSearch = (text) => {
    setApproverSearch(text);
  };

  useEffect(() => {
    console.log('customenv', env_type);
    env_type != null && (env_type === 'standard' || env_type === 'custom')
      ? (() => {
          console.log(customenv?.sub_category?.name);
          setSearchbu(customenv?.unit?.name);
          setSelectedCategoryId(customenv?.category_id);
          setCategorySearch(customenv?.sub_category?.category?.name);
          handleGetSubCategories(customenv?.category_id);
          setSubSearch(customenv?.sub_category?.name);
          form.setFieldsValue({
            project_related: customenv?.project?.id ? 'Yes' : 'No',
            description: customenv?.description
              ? customenv?.description
              : localStorage.getItem('description'),
            envelope_name: customenv?.title
              ? customenv?.title
              : localStorage.getItem('envelope_name'),
            min_price: customenv?.min_price,
            max_price: customenv?.max_price,
            Category: customenv?.category_id,
            'Sub-Category': customenv?.sub_category_id,
            project_code: customenv?.project?.id,
            Department: customenv?.unit?.id
          });
        })()
      : form.setFieldsValue({
          description: localStorage.getItem('description'),
          envelope_name: localStorage.getItem('envelope_name')
        });
  }, [env_type, customenv]);

  useEffect(() => {
    customenv?.envelope_type === 'standard'
      ? `${
          (localStorage.setItem('description', customenv?.description),
          localStorage.setItem('envelope_name', customenv?.title))
        }`
      : '';
  }, []);

  const handleOnChange = (e) => {
    if (e.length > 2) {
      setVendorName(e);
    }
  };

  const activeProjects = project_list?.filter((item) => item?.is_active);

  // const handleOnChange = (searchText) => {
  //   console.log(searchText);
  //   setVendors(!searchText ? [] : options);
  // };

  const zInd = 998;
  const user = LocalStorage.getItem('NFA_user');
  const Header = () => {
    return (
      <>
        {user.user_details.role === 'admin' ? (
          <AdminHeader
            project={project}
            handleSave={handleSave}
            edit_env={edit_env}
            handleEdit={handleEdit}
            envtype={envtype}
            type={type}
            projects={projects}
            projectId={projectId}
            customenv={customenv}
          />
        ) : (
          <div className={`w-100 ${styles.init_env_header}`}>
            <Row>
              <Link to="/dashboard">
                <div className={`df-jc-ac ${styles.x_button}`}>
                  <Close />
                </div>
              </Link>
              <Col span={19}>
                <div className={`ml-3 font-18 `}>
                  {envtype ? (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Form form={form} layout="vertical">
                          <FormItem name="envelope_name">
                            <Input
                              placeholder="Enter envelope name"
                              onBlur={(e) =>
                                handleEdit(e.target.value, 'envelope')
                              }
                              autoFocus
                            />
                          </FormItem>
                        </Form>
                      </Col>
                    </Row>
                  ) : customenv?.title ? (
                    customenv?.title
                  ) : (
                    `${localStorage.getItem('envelope_name')}`
                  )}
                  {!envtype && !customenv?.title ? (
                    <span
                      className={`ml-3`}
                      onClick={() => edit_env('envelope_name')}
                    >
                      <Pencil />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className={`ml-3 font-14`}>
                  {type ? (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Form form={form} layout="vertical">
                          <FormItem name="description">
                            <Input
                              placeholder="Enter description"
                              onBlur={(e) =>
                                handleEdit(e.target.value, 'description')
                              }
                              autoFocus
                            />
                          </FormItem>
                        </Form>
                      </Col>
                    </Row>
                  ) : customenv?.description ? (
                    customenv?.description
                  ) : (
                    `${localStorage.getItem('description')}`
                  )}
                  {!type && !customenv?.description ? (
                    <span
                      className={`ml-3`}
                      onClick={() => edit_env('description')}
                    >
                      <Pencil />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Col>

              <Col className="justify-content-end df-jb-ac" span={4}>
                {customenv?.Documents?.length > 0 ? (
                  <CustomButton
                    style={{ marginRight: '12px' }}
                    onClick={() => setFormatModal(true)}
                  >
                    Formats
                  </CustomButton>
                ) : (
                  ''
                )}
                <CustomButton
                  type="primary"
                  onClick={(e) => {
                    handleNext(e);
                  }}
                >
                  Next
                </CustomButton>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  };
  let chcked = env_type === 'custom' ? true : false;
  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {item.name} ({item.email})
        </span>
      </>
    );
  };

  // function formatPrice(priceString) {
  //   const [start, end] = priceString
  //     .replace('-', ' - ')
  //     .split('-')
  //     .map((num) => parseInt(num, 10));
  //   const formattedStart = isNaN(start)
  //     ? 'Invalid'
  //     : start.toLocaleString('en-IN');
  //   const formattedEnd = isNaN(end) ? 'Invalid' : end.toLocaleString('en-IN');
  //   return `${formattedStart} - ${formattedEnd}`;
  // }

  return (
    <>
      {envelopeDataLoading ? (
        <div
          style={{ height: '100vh', flexDirection: 'column' }}
          className="df-jc-ac h-100"
        >
          {' '}
          <Spin />
          Please wait
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          className={`ant-form ant-form-vertical  ${styles.form_width}`}
        >
          <Header />
          <div className={`${styles.container}`}>
            {user.user_details.role === 'user' ? (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <Badge
                        className={`${styles.badge}`}
                        count={1}
                        dot={false}
                      />
                    }
                    extra="Add envelope details"
                    key="1"
                  >
                    <Row
                      className="mt-4"
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32
                      }}
                    >
                      {env_type !== 'standard' && env_type !== 'custom' ? (
                        <Col className="gutter-row" span={24}>
                          <FormItem
                            label="Is this envelope related to a project?"
                            name="project_related"
                            rules={[
                              {
                                required: true,
                                message: ''
                              }
                            ]}
                          >
                            <Radio.Group onChange={handleCapacity}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                      ) : (
                        ''
                      )}
                      {env_type === 'standard' || env_type === 'custom' ? (
                        form.getFieldValue('project_related') === 'Yes' ||
                        formValues.project_related === 'Yes' ? (
                          <>
                            <Col className="gutter-row" xs={24} xl={6}>
                              <FormItem
                                label={`Project Code`}
                                name="project_code"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select project code'
                                  }
                                ]}
                              >
                                <Select
                                  showSearch
                                  // style={{ width: 200 }}
                                  onSearch={onSearch}
                                  placeholder="Select Project"
                                  onSelect={onSelectProject}
                                  filterOption={(inputValue, option) =>
                                    option.props.children
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  }
                                  disabled={
                                    customenv?.project?.id &&
                                    (env_type === 'standard' ||
                                      env_type === 'custom')
                                      ? true
                                      : ''
                                  }
                                >
                                  {project_list.length > 1
                                    ? activeProjects.map((item, index) => {
                                        return (
                                          <Option value={item.id} key={index}>
                                            {item.project
                                              ? item.project
                                              : item.title}
                                          </Option>
                                        );
                                      })
                                    : ''}
                                </Select>
                              </FormItem>
                            </Col>
                          </>
                        ) : (
                          ''
                        )
                      ) : env_type !== 'standard' && env_type !== 'custom' ? (
                        form.getFieldValue('project_related') === 'Yes' ||
                        formValues.project_related === 'Yes' ? (
                          <>
                            <Col className="gutter-row" xs={24} xl={6}>
                              <FormItem
                                label={`Project Code`}
                                name="project_code"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select project code'
                                  }
                                ]}
                              >
                                <Select
                                  showSearch
                                  // style={{ width: 200 }}
                                  onSearch={onSearch}
                                  placeholder="Select Project"
                                  onSelect={onSelectProject}
                                  filterOption={(inputValue, option) =>
                                    option.props.children
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  }
                                  disabled={
                                    customenv?.project?.id &&
                                    (env_type === 'standard' ||
                                      env_type === 'custom')
                                      ? true
                                      : false
                                  }
                                >
                                  {project_list.length > 1
                                    ? activeProjects.map((item, index) => {
                                        return (
                                          <Option value={item.id} key={index}>
                                            {item.project
                                              ? item.project
                                              : item.title}
                                          </Option>
                                        );
                                      })
                                    : ''}
                                </Select>
                              </FormItem>
                            </Col>
                          </>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      <Col className="gutter-row" xs={24} xl={6}>
                        <FormItem
                          label="Department"
                          name="Department"
                          rules={[
                            {
                              required: true,
                              message: 'Select Department'
                            }
                          ]}
                        >
                          <Select
                            suffixIcon={<CaretDownFilled />}
                            placeholder="Select Department"
                            disabled={
                              customenv?.unit?.id &&
                              (env_type === 'standard' || env_type === 'custom')
                                ? true
                                : false
                            }
                            onSearch={onSearchBu}
                            showSearch
                            filterOption={(inputValue, option) =>
                              option.props.children
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          >
                            {unit_list.length > 0
                              ? unit_list.map((item, index) => {
                                  return (
                                    <Option value={item?.id} key={index}>
                                      {item?.name}
                                    </Option>
                                  );
                                })
                              : ''}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col className="gutter-row" xs={24} xl={6}>
                        <FormItem
                          label="Min Price"
                          name="min_price"
                          rules={[
                            {
                              required:
                                env_type === 'standard' || env_type === 'custom'
                                  ? false
                                  : true,
                              message: 'Enter Min Price'
                            }
                          ]}
                        >
                          <InputNumber
                            precision={0}
                            formatter={(value) =>
                              value
                                ? Intl.NumberFormat('en-IN').format(value)
                                : undefined
                            }
                            className="w-100"
                            placeholder="Enter Min Price"
                            disabled={
                              customenv?.min_price &&
                              (env_type === 'standard' || env_type === 'custom')
                                ? true
                                : ''
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col className="gutter-row" xs={24} xl={6}>
                        <FormItem
                          label="Max Price"
                          name="max_price"
                          rules={[
                            {
                              required:
                                env_type === 'standard' || env_type === 'custom'
                                  ? false
                                  : true,
                              message: 'Enter Max Price'
                            }
                          ]}
                        >
                          <InputNumber
                            precision={0}
                            formatter={(value) =>
                              value
                                ? Intl.NumberFormat('en-IN').format(value)
                                : undefined
                            }
                            className="w-100"
                            placeholder="Enter Max Price"
                            disabled={
                              customenv?.max_price &&
                              (env_type === 'standard' || env_type === 'custom')
                                ? true
                                : ''
                            }
                          />
                        </FormItem>
                      </Col>
                      {/*env_type !== 'standard' && env_type !== 'custom' ? (*/}
                      <Col className="gutter-row" xs={24} xl={6}>
                        <FormItem
                          label="Total Value"
                          name="total_value"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Total value'
                            }
                          ]}
                        >
                          <InputNumber
                            className="w-100"
                            controls={false}
                            precision={0}
                            type="number"
                            placeholder="Enter Total value"
                          />
                        </FormItem>
                      </Col>
                      {/* ) : (
                        ''
                      )*/}
                      <Col className="gutter-row" xs={24} xl={6}>
                        <FormItem
                          label="Category"
                          name="Category"
                          rules={[
                            {
                              required: true,
                              message: 'Select Category'
                            }
                          ]}
                        >
                          <Select
                            suffixIcon={<CaretDownFilled />}
                            placeholder="Select Category"
                            showSearch
                            onChange={onChangeCategory}
                            disabled={
                              customenv?.category_id &&
                              (env_type === 'standard' || env_type === 'custom')
                                ? true
                                : ''
                            }
                            onSearch={onSearchCategory}
                            filterOption={(inputValue, option) =>
                              option.props.children
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          >
                            {categories
                              .filter((item) => item.new_cat_doa)
                              .map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col className="gutter-row" xs={24} xl={6}>
                        <FormItem
                          label="Sub-Category"
                          name="Sub-Category"
                          rules={[
                            {
                              required: true,
                              message: 'Select Sub-Category'
                            }
                          ]}
                        >
                          <Select
                            suffixIcon={<CaretDownFilled />}
                            placeholder="Select Sub-Category"
                            showSearch
                            disabled={
                              customenv?.sub_category_id &&
                              (env_type === 'standard' || env_type === 'custom')
                                ? true
                                : ''
                            }
                            onSearch={handleSubCategorySearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {subcategories
                              ?.filter((item) => item.new_sub_cat_doa)
                              .map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {item?.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Divider />
                      <Col className="gutter-row" span={24}>
                        <FormItem
                          label="Is this envelope related to a purchase or work order?"
                          name="purchase_order"
                          rules={[
                            {
                              required: true,
                              message: 'Select type'
                            }
                          ]}
                        >
                          <Radio.Group onChange={handleCapacity}>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </FormItem>
                      </Col>
                      {form.getFieldValue('purchase_order') === 'Yes' ||
                      formValues.purchase_work_order === 'Yes' ? (
                        <>
                          <Col className="gutter-row" xs={24} xl={6}>
                            <FormItem
                              label={`Available Budget`}
                              name="budget_ava"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter Available Budget'
                                }
                              ]}
                            >
                              <InputNumber
                                precision={0}
                                className="w-100"
                                placeholder="Enter Budget Availability"
                              />
                            </FormItem>
                          </Col>

                          <Col className="gutter-row" xs={24} xl={6}>
                            <FormItem
                              label={`Item Major Group`}
                              name="maj_group"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter Major Group'
                                }
                              ]}
                            >
                              <Input placeholder="Enter Major Group" />
                            </FormItem>
                          </Col>
                          <Col className="gutter-row" xs={24} xl={6}>
                            <FormItem
                              label={`Item Minor Group`}
                              name="min_group"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter Minor Group'
                                }
                              ]}
                            >
                              <Input placeholder="Enter Minor Group" />
                            </FormItem>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                      <Divider />

                      <Col className="gutter-row" span={24}>
                        <FormItem
                          label="Is this envelope related to a purchase requisition?"
                          name="purchase_req"
                          rules={[
                            {
                              required: true,
                              message: 'Select type'
                            }
                          ]}
                        >
                          <Radio.Group onChange={handleCapacity}>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </FormItem>
                      </Col>
                      {form.getFieldValue('purchase_req') === 'Yes' ||
                      formValues.purchase_requistion === 'Yes' ? (
                        <>
                          <Col className="gutter-row" xs={24} xl={6}>
                            <FormItem
                              label={`Vendor Name`}
                              name="vendor_name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter Vendor Name'
                                }
                              ]}
                            >
                              {/* <AutoComplete
                              options={options}
                              style={{
                                width: 200
                              }}
                              onSelect={onSelect}
                              onSearch={(e) => handleOnChange(e)}
                              placeholder="input here"
                            /> */}
                              {/* <Input
                              placeholder="Enter Vendor Name"
                              onChange={(e) => handleOnChange(e)}
                            /> */}
                              <AutoComplete
                                optionLabelProp="text"
                                onChange={(e) => handleOnChange(e)}
                                onSelect={onSelectVendor}
                                placeholder="Select Vendorname"
                              >
                                {vendors.map((item, index) => (
                                  <Option key={index} value={item?.vendor_name}>
                                    {item?.vendor_name}
                                  </Option>
                                ))}
                              </AutoComplete>
                            </FormItem>
                          </Col>
                          <Col className="gutter-row" xs={24} xl={6}>
                            <FormItem
                              label={`Vendor ERP Code`}
                              name="vendor_erp_code"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter Vendor ERP Code'
                                }
                              ]}
                            >
                              <Input placeholder="Enter Vendor ERP Code" />
                            </FormItem>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Panel>
                </CustomCollapse>

                <br />
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <Badge
                        className={`${styles.badge}`}
                        count={2}
                        dot={false}
                      />
                    }
                    extra="Upload documents"
                    key="2"
                  >
                    {fileList?.length ? (
                      <DisplayFiles
                        key={fileList.name}
                        files={fileList}
                        handleOnChangePdfUpload={handleOnChangePdfUpload}
                        handleOnDropPdfUpload={handleOnDropPdfUpload}
                        removeInputFields={removeInputFields}
                      />
                    ) : (
                      <Dragger
                        name="file"
                        accept=".pdf"
                        listType="picture-card"
                        className={`avatar-uploader ${styles.upload}`}
                        onChange={handleOnChangePdfUpload}
                        onDrop={handleOnDropPdfUpload}
                        customRequest={({ onSuccess }) => {
                          setTimeout(() => {
                            onSuccess('ok');
                          }, 0);
                        }}
                        multiple={true}
                        showUploadList={false}
                      >
                        <div className="p-3">
                          <div className={`${styles.upgrade}`}>
                            <Image
                              className={`df-jb-ac ${styles.image}`}
                              src={upload}
                              preview={false}
                            />
                          </div>

                          <div className={`${styles.upgrade}`}>
                            <span>Drag and drop your files here</span>
                          </div>
                          <div className={`mb-2 ${styles.upgrade}`}>
                            <span>or</span>
                          </div>
                          <div className={`${styles.upgrade}`}>
                            <CustomButton type="primary">Upload</CustomButton>
                          </div>
                        </div>
                      </Dragger>
                    )}
                  </Panel>
                </CustomCollapse>
              </>
            ) : (
              ''
            )}
            <br />
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <Badge
                    className={`${styles.badge}`}
                    count={user.user_details.role === 'user' ? 3 : 1}
                    dot={false}
                  />
                }
                extra={
                  env_type === 'standard' ? 'Approvers List' : 'Add approvers'
                }
                key="3"
              >
                {env_type !== 'standard' ? (
                  <div>
                    <Checkbox
                      className={`font-14`}
                      onChange={onCheckBoxToggle}
                      defaultChecked={chcked}
                      checked={signOrder}
                    >
                      Set signing order
                    </Checkbox>
                  </div>
                ) : (
                  ''
                )}
                <br />
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable
                    droppableId="list-container"
                    className={`${styles.approver_line}`}
                  >
                    {(provided) => (
                      <div
                        className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {itemList.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <div
                                  className={
                                    env_type === 'standard'
                                      ? `item-container ${styles.nodragndrop}`
                                      : 'item-container'
                                  }
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col className="gutter-row" span={1}>
                                      <div
                                        className={`
                                        ${
                                          item.ind > 1
                                            ? `${styles.step} ${styles.v_stepper}${styles.circle} `
                                            : ''
                                        } mt-2`}
                                      >
                                        <div className={`${styles.circle}`}>
                                          <p>{index + 1}</p>
                                        </div>
                                        <div
                                          className={
                                            itemList.length !== index + 1
                                              ? `${styles.line}`
                                              : ''
                                          }
                                        ></div>
                                      </div>
                                    </Col>
                                    {env_type !== 'standard' ? (
                                      <Col className="gutter-row" span={1}>
                                        <div className="mt-2">
                                          <HolderOutlined />
                                        </div>
                                      </Col>
                                    ) : (
                                      ''
                                    )}
                                    <Col className="gutter-row" xs={17} xl={16}>
                                      <div
                                        className={
                                          item.envtype !== ''
                                            ? `${styles.approverdisIndex}`
                                            : `${styles.approverIndex}`
                                        }
                                      >
                                        <FormItem
                                          {...item}
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: 'This field is required'
                                            }
                                          ]}
                                          name="approvers"
                                          noStyle
                                          className="aasdsad"
                                        >
                                          <ReactSearchAutocomplete
                                            fuseOptions={{
                                              keys: ['name', 'email']
                                            }}
                                            onSearch={(e) =>
                                              handleApproverSearch(e)
                                            }
                                            inputDebounce={300}
                                            items={selectedRows}
                                            showIcon={false}
                                            formatResult={formatResult}
                                            placeholder="Select Approver"
                                            onSelect={onSelect}
                                            autocomplete="on"
                                            // showClear={false}
                                            showClear
                                            styling={{
                                              height: '34px',
                                              border: '1px solid darkgreen',
                                              borderRadius: '4px',
                                              backgroundColor: 'white',
                                              boxShadow: 'none',
                                              fontSize: '12px',
                                              clearIconMargin: '3px 8px 0 0',
                                              zIndex: `${zInd - index}`
                                            }}
                                            inputSearchString={
                                              selectedOption[index]
                                                ? selectedOption[index]
                                                    .job_title
                                                  ? selectedOption[index].name +
                                                    ' - (' +
                                                    selectedOption[index]
                                                      .job_title +
                                                    ')' +
                                                    ' (' +
                                                    selectedOption[index]
                                                      .email +
                                                    ')'
                                                  : selectedOption[index].name +
                                                    ' (' +
                                                    selectedOption[index]
                                                      .email +
                                                    ')'
                                                : ''
                                            }
                                          />
                                        </FormItem>
                                      </div>
                                    </Col>
                                    {/* <Col className="gutter-row" span={4}>
                                    <div className="mt-2">
                                      <b>Needs to sign</b>
                                    </div>
                                  </Col> */}
                                    {item.value === '' ? (
                                      <span onClick={() => removeInput(index)}>
                                        <Delete
                                          className={`cursor-pointer ${styles.delete_icon}`}
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </Row>
                                </div>
                                <br />
                              </>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {env_type !== 'standard' ? (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col className="gutter-row" span={4}>
                        <div type="button" onClick={() => addInput()}>
                          <span className={`cursor-pointer ${styles.span_add}`}>
                            <PlusCircleFilled />
                            {'\u00a0'} Add Approvers
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </DragDropContext>
              </Panel>
            </CustomCollapse>
            <br />
            {user.user_details.role === 'user' ? (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                >
                  <Panel
                    className={` ${styles.panel}`}
                    header={
                      <Badge
                        className={` ${styles.badge}`}
                        count={4}
                        dot={false}
                      />
                    }
                    extra="Add message"
                    key="4"
                  >
                    <FormItem
                      label="Add email messsage"
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Total value'
                        }
                      ]}
                    >
                      <TextArea
                        className={` ${styles.text_area}`}
                        rows={4}
                        placeholder={'Enter message'}
                        onChange={handleTextAreaChange}
                        /*  value={emailmessage || ''} */
                      />
                    </FormItem>
                  </Panel>
                </CustomCollapse>
              </>
            ) : (
              ''
            )}
            <br />
          </div>
          {formatModal ? (
            <FormatDocs
              formatModal={formatModal}
              setFormatModal={setFormatModal}
              customenv={customenv}
            />
          ) : (
            ''
          )}
        </Form>
      )}
    </>
  );
};

export default InititateEnvelopePage1;
